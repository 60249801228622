import { ModalContent, Text } from "@zapier/design-system";

import { Modal } from "components/Modal";
import CreatedResources from "components/CreatedResources";
import LoadingCard from "components/Templates/LoadingCard";
import { styled } from "lib/theme";

import { Dispatch, SetStateAction } from "react";
import {
  ZslApiTemplateExecuteOutput,
  ZslTemplate,
} from "@/server/services/zslapi";

const Cards = styled.div`
  text-align: center;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export type DuplicateModalState = {
  status: "cloning" | "creating" | "finished" | "error";
  zsl: ZslTemplate | null;
  createdTemplate?: ZslApiTemplateExecuteOutput | null;
};

interface DuplicateModalProps {
  closeModal: () => void;
  setState: Dispatch<SetStateAction<DuplicateModalState | null>>;
  state: DuplicateModalState;
}

const DuplicateModal = ({ closeModal, state }: DuplicateModalProps) => {
  const renderBody = () => {
    if (state.status === "cloning") {
      return (
        <Cards>
          <LoadingCard kind="interface" />
        </Cards>
      );
    }

    if (state.status === "creating" && state.zsl) {
      return (
        <Cards>
          {state.zsl.chatbots?.map((_, index) => (
            <LoadingCard key={`chatbot-${index}`} kind="chatbot" />
          ))}
          {state.zsl.zaps?.map((_, index) => (
            <LoadingCard key={`zap-${index}`} kind="zap" />
          ))}
          {state.zsl.interfaces?.map((_, index) => (
            <LoadingCard key={`interface-${index}`} kind="interface" />
          ))}
          {state.zsl.tables?.map((_, index) => (
            <LoadingCard key={`table-${index}`} kind="table" />
          ))}
        </Cards>
      );
    }

    if (state.status === "finished" && state.createdTemplate) {
      return (
        <Cards>
          <CreatedResources
            createdTemplate={state.createdTemplate}
            slug="duplicate-modal"
          />
        </Cards>
      );
    }
  };

  let text = "Cloning your project...";
  if (state.status === "creating") text = "Creating all assets...";
  else if (state.status === "finished")
    text = "Your project has been duplicated!";
  else if (state.status === "error") text = "An error occurred while cloning.";

  return (
    <Modal onClosed={closeModal}>
      <ModalContent maxWidth="1000px">
        <Text type="pageHeader7">{text}</Text>
        {renderBody()}
      </ModalContent>
    </Modal>
  );
};

export default DuplicateModal;
