import {
  chatbotUrl,
  getEditTableUrl,
  getEditZapUrl,
  projectUrl,
} from "lib/route-helpers";

import { useCurrentAccountId } from "@zapier/identity";
import ResourceCard from "./ResourceCard";
import { useMixpanelContext } from "observability/mixpanel";
import { ZslApiTemplateExecuteOutput } from "@/server/services/zslapi";

interface TemplateCreationScreenProps {
  createdTemplate: ZslApiTemplateExecuteOutput;
  slug: string;
}

const CreatedResources = ({
  createdTemplate,
  slug,
}: TemplateCreationScreenProps) => {
  const currentAccountId = useCurrentAccountId();

  const { track: mixpanelTrack } = useMixpanelContext();

  const trackEvent = (kind: string) => {
    mixpanelTrack("Clicked View Asset", {
      template: slug,
      kind,
      component: "CreatedResources",
    });
  };

  return (
    <>
      {Object.keys(createdTemplate?.chatbots).map((id) => {
        const name = createdTemplate.chatbots[id]?.config.chatbotName;

        return (
          <ResourceCard
            key={id}
            kind="chatbot"
            href={chatbotUrl(id)}
            name={name}
            trackClick={trackEvent}
          />
        );
      })}
      {Object.keys(createdTemplate?.zaps).map((id) => {
        const { title } = createdTemplate.zaps[id];

        return (
          <ResourceCard
            key={id}
            kind="zap"
            href={getEditZapUrl({
              rootNodeId: id,
              accountId: currentAccountId,
            })}
            name={title}
            trackClick={trackEvent}
          />
        );
      })}
      {Object.keys(createdTemplate?.tables).map((id) => {
        const { name } = createdTemplate.tables[id];

        return (
          <ResourceCard
            key={id}
            kind="table"
            href={getEditTableUrl({ tableId: id })}
            name={name}
            trackClick={trackEvent}
          />
        );
      })}
      {Object.keys(createdTemplate?.interfaces).map((id) => {
        const { name } = createdTemplate.interfaces[id];

        return (
          <ResourceCard
            key={id}
            kind="interface"
            href={projectUrl({ projectId: id })}
            name={name}
            trackClick={trackEvent}
          />
        );
      })}
      {Object.keys(createdTemplate?.canvases).map((id) => {
        const { name } = createdTemplate.canvases[id];

        return (
          <ResourceCard
            key={id}
            kind="canvas"
            href={`/app/canvas/${id}`}
            name={name}
            trackClick={trackEvent}
          />
        );
      })}
    </>
  );
};

export default CreatedResources;
