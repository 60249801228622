import { DEFAULT_PROJECT_FAVICON } from "server/models/util/project-constants";
import { UploadcareImage } from "server/schemas/projects";
import { LibraryTemplate } from "@/server/services/zslapi";
import { TemplateItemProps } from "./TemplateItem";

export type TemplateItem = Pick<
  TemplateItemProps,
  "title" | "description" | "previewImage" | "icon" | "tablesCount" | "zsl"
> & { id: string };

export function projectTemplateToTemplateSelectorProps(
  template: LibraryTemplate
): TemplateItem {
  const icon: UploadcareImage = template.meta?.icon ?? DEFAULT_PROJECT_FAVICON;
  const iconUrl = icon.url.includes("ucarecdn.com")
    ? `${icon.url}-/preview/70`
    : icon.url;

  return {
    id: template.key,
    title: template.name,
    description: template.description,
    previewImage: template.previewImage?.url,
    icon: iconUrl,
    tablesCount: (template.tables ?? []).length,
    zsl: template,
  };
}
