import {
  Modal,
  ModalContentBody,
  ModalContentFooter,
  ModalContentHeader,
  Spacer,
  Text,
} from "@zapier/design-system";
import { styled } from "lib/theme";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";

import { CustomModalContent } from "components/Modal/CustomModalContent";
import { Spinner } from "components/Spinner";
import { Category } from "lib/contentful/lib/client";
import { TablesProvider } from "lib/context/tables-context";
import { ClientUserProfile } from "lib/context/user-context";
import { pagePath, projectPath } from "lib/route-helpers";
import { useTrackingContext } from "observability";
import { toast } from "react-hot-toast";
import { config } from "@/config";
import { trpc } from "utils/trpc";
import { useMixpanelContext } from "../../observability/mixpanel";
import { LoadingContent } from "./LoadingContent";

import customerPortalThumbnail from "assets/images/project-category-thumbnails/customer-portal.png";
import formsThumbnail from "assets/images/project-category-thumbnails/forms.png";

import { startCase } from "lodash";
import { StaticImageData } from "next/image";
import { LibraryTemplate, ZslTemplate } from "@/server/services/zslapi";
import { CategorySelection } from "./CategorySelection";
import { FooterActions } from "./FooterActions";
import { TemplateSelector } from "./TemplateSelector";
import { useContentful } from "@zapier/platform-ui";
import { zrpc } from "@/lib/zrpc";

export type CategoryWithPreviewImage = Category & {
  description: string;
  previewImage: StaticImageData;
};

export const categories: CategoryWithPreviewImage[] = [
  {
    name: "Form",
    slug: "forms",
    description:
      "Forms can be used to capture information from your leads or customers. You can embed forms on your website or publish them directly.",
    previewImage: formsThumbnail,
  },
  {
    name: "Customer Portal",
    slug: "customer-portal",
    description:
      "Create web portals for your team or customers. Portals can either be public or require users to login. Embed tables, kanban boards, and more!",
    previewImage: customerPortalThumbnail,
  },
];

export const templateKeysByCategory: Record<
  CategoryWithPreviewImage["slug"],
  LibraryTemplate["key"][]
> = {
  forms: [
    "get-in-touch",
    "free-offer",
    "invoice-form",
    "employee-availability-form-tracker",
  ],
  "customer-portal": [
    "marketplace",
    "product-feedback",
    "request-portal",
    "simple-crm",
  ],
};

const ZAPIER_BASE_URL = config().NEXT_PUBLIC_ZAPIER_API_BASE_URL;

export const SpinnerWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

const DEFAULT_TABLES_LIMIT = config().DEFAULT_TABLES_LIMIT;

type ModalCreateProjectProps = {
  accountId: number;
  onClosed: () => void;
  templateKey?: string | null;
  user: ClientUserProfile;
};

export function ModalCreateProject(props: Readonly<ModalCreateProjectProps>) {
  const ZAPIER_UPGRADE_URL = `${ZAPIER_BASE_URL}/app/settings/billing?upgradeProduct=tables-premium?utm_campaign=zpr-gbl-exp-evr-add_on_purchase_interfaces_product-prd&utm_medium=product&utm_source=zapier`;

  const [selectedCategory, setSelectedCategory] =
    useState<CategoryWithPreviewImage["slug"]>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const router = useRouter();

  const { emitBuilderInteractionEvent } = useTrackingContext();
  const { track: mixpanelTrack } = useMixpanelContext();

  const utils = trpc.useUtils();

  const { templates = [] }: { templates: LibraryTemplate[] } = useContentful(
    config().NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    () => true, // No filter needed beyond primary product.
    "interface"
  );

  const { mutateAsync: deleteInterface } = trpc.projects.delete.useMutation();

  const { data: tables, isLoading: isLoadingTables } =
    trpc.tableTables.list.useQuery(undefined);
  const tablesCount = tables?.data.length ?? 0;

  const { data: limits, isLoading: isLoadingLimits } =
    trpc.tableTables.limits.useQuery(undefined);
  const tablesLimit = limits?.data.max_table_count ?? DEFAULT_TABLES_LIMIT;

  const planStatusAmount: number =
    props.user.entitlements.plan_status?.amount ?? 0;
  const isPaidAccount = planStatusAmount > 0;

  const { mutateAsync: executeTemplate } = zrpc.zslapi.useMutation(
    "post",
    "/api/zsl/v0/templates/execute",
    {
      onSuccess(response) {
        const createdInterface = response.data.interfaces[0];
        utils.projects.list.setData(undefined, (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            projects: [...prev, createdInterface],
          };
        });
      },
      onError(err) {
        let toastMessage = "Interface creation failed.";

        const isTableLimit = err.errors
          .map((e) => e.detail)
          .includes("Created table count did not match template input");

        const isProjectLimit = err.errors.some(
          (e) => e.code === "interfaces:limits:projects"
        );

        if (isTableLimit) {
          toastMessage = `Upgrade your Tables limits to use this template.`;
        } else if (isProjectLimit) {
          toastMessage = `You have reached the limit of Interfaces for your plan. Please upgrade your plan to create more.`;
          setTimeout(() => {
            window.location.assign(
              `${config().NEXT_PUBLIC_INTERFACES_BASE_URL}/pricing`
            );
          }, 5000);
        }

        toast.error(toastMessage);
      },
    }
  );

  const createFromTemplate = useCallback(
    async (template: ZslTemplate) => {
      setIsSubmitting(true);

      let templateKey;
      if ("key" in template && typeof template.key === "string") {
        templateKey = template.key;
      }

      try {
        const { data } = await executeTemplate({
          body: { input: {}, template },
        });

        const project = Object.values(data.interfaces)[0];

        emitBuilderInteractionEvent({
          interaction_raw_text: templateKey,
          interaction_goal: "create new project",
          interfaces_project_id: project?.id,
          event_action: "click",
        });

        // Redirect to the project
        if (project) {
          const redirectPath = projectPath({ projectId: project.id });
          void router.push(redirectPath);
        }
      } catch (err: any) {
        console.error(err);

        setIsSubmitting(false);
        return;
      }
    },
    [emitBuilderInteractionEvent, executeTemplate, router]
  );

  const { mutateAsync: createInterface } = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/interfaces",
    {}
  );
  const { mutateAsync: createPage } = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/interfaces/{interfaceId}/pages",
    {}
  );

  const createBlankInterface = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const newInterface = await createInterface({
        body: { name: "New Interface" },
      });

      const page = await createPage({
        params: { path: { interfaceId: newInterface.id } },
        body: { name: "Page" },
      }).catch(async (createPageErr) => {
        await deleteInterface({ projectIds: [newInterface.id] });
        throw createPageErr;
      });

      const redirectPath = pagePath({ pageId: page.id });

      void router.push(redirectPath);
    } catch (err: unknown) {
      console.error(`Failed to create blank interface`, err);
      toast.error(`There was a problem creating the new interface`);
      setIsSubmitting(false);
    }
  }, [createInterface, createPage, deleteInterface, router]);

  const handleBlankClick = useCallback(() => {
    emitBuilderInteractionEvent({
      interaction_raw_text: `Clicked start from scratch button`,
      interaction_goal: `click start from scratch`,
      event_action: "click",
    });
    void createBlankInterface();
  }, [emitBuilderInteractionEvent, createBlankInterface]);

  const templatesForSelectedCategory = useMemo(() => {
    if (!selectedCategory) return [];
    return templates.filter((t) =>
      templateKeysByCategory[selectedCategory]?.includes(t.key)
    );
  }, [templates, selectedCategory]);

  const renderBody = () => {
    if (isSubmitting) return <LoadingContent />;

    return (
      <CustomModalContent width="800px">
        <ModalContentHeader>
          <ModalContentHeaderLayout>
            <Text type="pageHeader7">
              {selectedCategory
                ? `Choose a ${startCase(
                    selectedCategory
                  ).toLowerCase()} template to start with`
                : "What do you want to build today?"}
            </Text>
          </ModalContentHeaderLayout>
        </ModalContentHeader>
        <ModalContentBody scrollable>
          {isLoadingTables || isLoadingLimits ? (
            <SpinnerWrapper>
              <SpinnerLayout>
                <Spinner />
              </SpinnerLayout>
            </SpinnerWrapper>
          ) : (
            <TablesProvider accountId={props.accountId}>
              {!selectedCategory ? (
                <CategorySelection
                  categories={categories}
                  onSelect={(category: CategoryWithPreviewImage) => {
                    mixpanelTrack("Clicked Template Category", {
                      reactComponent: "ModalCreateProject",
                      category: category,
                      experimentName: "interfaces_enable-top-level-templates",
                      experimentLane: "control",
                    });
                    emitBuilderInteractionEvent({
                      interaction_raw_text: `Clicked ${category.name} category`,
                      interaction_goal: `Click onboarding template category`,
                      event_action: "click",
                    });
                    setSelectedCategory(category.slug);
                  }}
                />
              ) : null}

              {selectedCategory ? (
                <TemplateSelector
                  templates={templatesForSelectedCategory}
                  onSelect={(template) => createFromTemplate(template)}
                  onUpgrade={(templateKey) => {
                    emitBuilderInteractionEvent({
                      interaction_raw_text: `Upgrade Tables limits to create a ${templateKey} project`,
                      interaction_goal: "Upgrade Tables limits",
                      event_action: "click",
                    });
                  }}
                  isPaidAccount={isPaidAccount}
                  tablesCount={tablesCount}
                  tablesLimit={tablesLimit}
                  zapierUpgradeUrl={ZAPIER_UPGRADE_URL}
                />
              ) : null}
            </TablesProvider>
          )}
        </ModalContentBody>
        <Spacer height={20} />
        <ModalContentFooter>
          <ModalFooterLayout>
            <FooterActions
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              handleBlankClick={handleBlankClick}
              baseUrl={ZAPIER_BASE_URL}
            />
            <Spacer height={20} />
          </ModalFooterLayout>
        </ModalContentFooter>
      </CustomModalContent>
    );
  };

  return (
    <Modal
      onClosed={props.onClosed}
      canClose={!isSubmitting}
      ariaLabel="Create project"
    >
      {renderBody()}
    </Modal>
  );
}

const SpinnerLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 560px;
`;

export const ModalContentHeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px -20px 30px 0;
`;

export const ModalFooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
