import { ZslTemplate } from "@/server/services/zslapi";

const formTemplate: ZslTemplate = {
  name: "Form",
  chatbots: [],
  zaps: [],
  tables: [],
  interfaces: [
    {
      id: "_INTERFACE_1",
      name: "Form Interface",
      homepage: "_PAGE_1",
      pages: [
        {
          id: "_PAGE_1",
          name: "Page",
          navigationEnabled: true,
          blocks: [
            {
              type: "markdown-text-block",
              config: {
                id: "_BLOCK_1",
                content: "# Welcome to my form\n\nTry out the form below",
                textAlign: "center",
                style: {
                  width: "narrow",
                  alignment: "center",
                },
              },
            },
            {
              type: "form-block",
              config: {
                id: "_BLOCK_2",
                submitText: "Submit",
                triggers: [
                  {
                    type: "notification",
                    config: {
                      id: "_ACTION_1",
                      message: "Form submitted!",
                      position: "top-center",
                    },
                  },
                ],
                style: {
                  width: "narrow",
                  alignment: "center",
                },
                captchaEnabled: false,
              },
              children: [
                {
                  type: "field-block",
                  config: {
                    id: "_BLOCK_1",
                    name: "field-1742",
                    nameAutoGenerated: true,
                    required: true,
                    label: "Email",
                    conditionalLogic: [],
                    inputType: "email",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  parameters: [],
};

export default formTemplate;
