import React, { useState } from "react";

import styled from "styled-components";
import { Spacer, Text } from "@zapier/design-system";

import TemplateHandler from "components/TemplateHandler";
import { TemplateGrid } from "components/TemplateGrid";
import { SimpleCard } from "./SimpleCard";
import FormImage from "./form.png";
import TableImage from "./table.png";
import KanbanImage from "./kanban.png";
import Header from "./Header";
import formTemplate from "./SimpleTemplates/form";
import kanbanTemplate from "./SimpleTemplates/kanban";
import { useRouter } from "next/router";
import { LoadingModal } from "components/LoadingModal";
import tableTemplate from "./SimpleTemplates/table";
import { projectPath } from "lib/route-helpers";
import { zrpc } from "@/lib/zrpc";
import { type ZslTemplate } from "@/server/services/zslapi";

const SimpleCards = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin: 25px 0 60px;
  grid-gap: 20px;
`;

interface ZeroStateProps {
  className?: string;
  onClick: () => void;
}

export function ZeroState({ onClick }: ZeroStateProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  const { mutateAsync: executeTemplate } = zrpc.zslapi.useMutation(
    "post",
    "/api/zsl/v0/templates/execute"
  );

  const createFromTemplate = async (zsl: ZslTemplate) => {
    setLoading(true);

    const response = await executeTemplate({
      body: { template: zsl, input: {} },
    });

    const projectId = Object.keys(response.data?.interfaces)[0];

    if (projectId) {
      const path = projectPath({ projectId: projectId });
      return router.push(path);
    }

    setLoading(false);
  };

  return (
    <section data-testid="projects-zero-state">
      <Header startBlank={onClick} />
      <Spacer height={20} />
      <Text type="subHeader5Medium">Start simple</Text>
      <SimpleCards title="Simple templates">
        <li className="w-full">
          <SimpleCard
            title="Form"
            image={FormImage}
            onClick={() => createFromTemplate(formTemplate)}
            triggers={["Form is submitted"]}
          />
        </li>
        <li className="w-full">
          <SimpleCard
            title="Table"
            image={TableImage}
            onClick={() => createFromTemplate(tableTemplate)}
            triggers={[
              "New record is added",
              "Record is updated",
              "Zap button is clicked",
            ]}
          />
        </li>
        <li className="w-full">
          <SimpleCard
            title="Kanban"
            image={KanbanImage}
            onClick={() => createFromTemplate(kanbanTemplate)}
            triggers={[
              "Card changes columns",
              "New card is created",
              "Card details are updated",
            ]}
          />
        </li>
      </SimpleCards>
      <Text type="subHeader5Medium">Templates</Text>
      <TemplateGrid handleCreate={createFromTemplate} />
      <TemplateHandler />
      {loading ? <LoadingModal /> : null}
    </section>
  );
}
