import styled from "styled-components";
import {
  ColorsCSS as Colors,
  Icon,
  Shadows,
  SkeletonText,
  Spacer,
  Text,
} from "@zapier/design-system";
import { Usage } from "./Usage";
import { LibraryTemplate } from "@/server/services/zslapi";

const Wrapper = styled.button`
  appearance: none;
  border-radius: 5px;
  border: 1px solid ${Colors.neutral400};
  background: ${Colors.neutral100};
  text-align: left;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition:
    background 150ms,
    border-color 150ms,
    box-shadow 150ms;
  &:hover,
  &:focus {
    background: ${Colors.blue100};
    border-color: ${Colors.blue600};
    box-shadow: ${Shadows.elevation5};
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 6px;
  span {
    line-height: 1.4em !important;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;

const Description = styled.div`
  margin-bottom: 8px;
  flex: 1;
  line-height: 1.3em;
`;

const UseTemplateText = styled.span`
  appearance: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface SimpleTemplateCardProps {
  createTemplate?: () => void;
  template?: LibraryTemplate;
}

export const SimpleTemplateCard = ({
  createTemplate,
  template,
}: SimpleTemplateCardProps) => {
  return (
    <Wrapper onClick={createTemplate}>
      <TitleWrapper>
        <Text type="paragraph1Semibold">
          {template?.name || (
            <>
              <SkeletonText size="heading" lines={1} />
              <Spacer height={8} />
            </>
          )}
        </Text>
      </TitleWrapper>
      <Description>
        <Text type="smallPrint1">
          {template?.description || <SkeletonText lines={3} />}
        </Text>
      </Description>
      <Footer>
        {template ? (
          <>
            <Usage count={template.meta?.numberOfUses || 0} />
            <Text type="smallPrint1">
              <UseTemplateText onClick={createTemplate}>
                <Icon name="formAdd" size={16} />
                Use template
              </UseTemplateText>
            </Text>
          </>
        ) : (
          <>
            <SkeletonText width={32} lines={1} />
            <SkeletonText width={100} lines={1} />
          </>
        )}
      </Footer>
    </Wrapper>
  );
};
