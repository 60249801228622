import styled from "styled-components";
import { FC, useMemo, useState } from "react";
import { EmptyState } from "./EmptyState";
import FilterBar from "./FilterBar";
import { Button } from "@zapier/design-system";
import { SimpleTemplateCard } from "components/SimpleTemplateCard";
import { useContentful } from "@zapier/platform-ui";
import { config } from "@/config";
import { LibraryTemplate } from "@/server/services/zslapi";

type TemplateGridProps = {
  handleCreate: (template: LibraryTemplate) => void;
};

const TemplatesWrapper = styled.ul`
  display: grid;
  grid-gap: 20px;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ViewMoreWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const TemplateGrid: FC<TemplateGridProps> = ({ handleCreate }) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [category, setCategory] = useState<string>("popular");
  const [role, setRole] = useState<string>("");

  const {
    templates = [],
    roles = [],
    categories = [],
    isLoading,
  } = useContentful(
    config().NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    () => true, // No filter needed beyond primary product.
    "interface"
  );

  const search = query.toLowerCase();
  let canShowMore = false;

  let filteredTemplates: LibraryTemplate[] = useMemo(() => {
    let filteredTemplates = [...templates];

    if (search.length >= 2) {
      filteredTemplates = filteredTemplates.filter(
        (t) =>
          t.name.toLowerCase().includes(search) ||
          t.description?.toLowerCase().includes(search)
      );
    }

    if (category) {
      if (category === "popular") {
        filteredTemplates = filteredTemplates.sort(
          (a, b) => b.meta.numberOfUses - a.meta.numberOfUses
        );
      } else {
        filteredTemplates = filteredTemplates.filter((t) =>
          t.meta.categories.includes(category)
        );
      }
    }

    if (role) {
      filteredTemplates = filteredTemplates.filter(
        (t) => t.meta.roles && t.meta.roles.includes(role)
      );
    }

    return filteredTemplates;
  }, [templates, search, category, role]);

  if (!showAll && filteredTemplates.length > 6) {
    filteredTemplates = filteredTemplates.slice(0, 6);
    canShowMore = true;
  }

  const renderBody = () => {
    if (isLoading) {
      return (
        <TemplatesWrapper>
          {Array.from({ length: 6 }).map((_, n) => (
            <li key={`template-${n}`}>
              <SimpleTemplateCard template={undefined} />
            </li>
          ))}
        </TemplatesWrapper>
      );
    }

    if (filteredTemplates.length === 0) {
      return <EmptyState />;
    }

    return (
      <>
        <TemplatesWrapper title="Templates">
          {filteredTemplates.map((t) => (
            <li key={`template-${t.key}`}>
              <SimpleTemplateCard
                createTemplate={() => handleCreate(t)}
                template={t}
              />
            </li>
          ))}
        </TemplatesWrapper>
        {canShowMore ? (
          <ViewMoreWrapper>
            <Button
              //  @ts-ignore-next-line (isPill is a valid prop)
              isPill
              size="small"
              color="primary-alt"
              iconBefore="arrowBigDown"
              onClick={() => setShowAll(true)}
            >
              View more
            </Button>
          </ViewMoreWrapper>
        ) : null}
      </>
    );
  };

  return (
    <>
      <FilterBar
        category={category}
        categories={categories}
        setCategory={setCategory}
        role={role}
        roles={roles}
        setRole={setRole}
        query={query}
        setQuery={setQuery}
      />
      {renderBody()}
    </>
  );
};
