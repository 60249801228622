import { ZslTemplate } from "@/server/services/zslapi";

const kanbanTemplate: ZslTemplate = {
  name: "Kanban",
  chatbots: [],
  zaps: [],
  tables: [
    {
      id: "_TABLE_1",
      name: "Kanban",
      fields: [
        {
          id: "_FIELD_2",
          name: "Title",
          type: "string",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            icon: "formText",
            alignment: "left",
          },
        },
        {
          id: "_FIELD_3",
          name: "Description",
          type: "string",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            icon: "formText",
            alignment: "left",
          },
        },
        {
          id: "_FIELD_4",
          name: "Status",
          type: "labeled_string",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            choice_provider: {
              kind: "static",
              choices: [
                {
                  value: "To Do",
                  label: "To Do",
                  color: "#fadfd9",
                },
                {
                  value: "Doing",
                  label: "Doing",
                  color: "#fff4bc",
                },
                {
                  value: "Done",
                  label: "Done",
                  color: "#def3e9",
                },
              ],
            },
            icon: "arrowDropdownCircle",
            alignment: "left",
          },
        },
      ],
    },
  ],
  interfaces: [
    {
      id: "_INTERFACE_1",
      name: "Kanban",
      homepage: "_PAGE_1",
      pages: [
        {
          id: "_PAGE_1",
          name: "Page",
          navigationEnabled: true,
          blocks: [
            {
              type: "kanban-block",
              config: {
                id: "_BLOCK_1",
                title: "My kanban",
                tableId: "${{ tables._TABLE_1 }}",
                groupFieldId: "${{ tables._TABLE_1._FIELD_4 }}",
                cardTitleFieldId: "${{ tables._TABLE_1._FIELD_2 }}",
                cardDescriptionFieldId: "${{ tables._TABLE_1._FIELD_3 }}",
                cardDetailFields: [
                  "${{ tables._TABLE_1._FIELD_2 }}",
                  "${{ tables._TABLE_1._FIELD_4 }}",
                  "${{ tables._TABLE_1._FIELD_3 }}",
                ],
                selectedColumns: [
                  {
                    value: "To Do",
                    label: "To Do",
                  },
                  {
                    value: "Doing",
                    label: "Doing",
                  },
                  {
                    value: "Done",
                    label: "Done",
                  },
                ],
                canCreateCards: true,
                canUpdateCards: true,
                canDeleteCards: true,
                canMoveCards: true,
                canMoveColumns: false,
                style: {
                  width: "wide",
                  alignment: "center",
                },
              },
            },
          ],
        },
      ],
    },
  ],
  parameters: [],
};

export default kanbanTemplate;
